<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
    <PopupPromotion ref="popupPromotionRef" :promotion="currentPromo" :indexPromo="currentIndex" @deletePromoEvent="deletePromotion" @activatePromotionEv="activatePromotion" />
    <b-row>
          
          <b-col
            :md="window.innerWidth<=1200 && window.innerWidth>1024 ? 6 : 5"
           
            class="mb-1"
          >
  <b-card title="Générer un nouveau code" class="card-grey">
    <div class="round-element black">
          <img src='@/assets/images/recycle.svg' class="icon" width="70" />
        </div>
    <PromotionForm @refreshListEv="refreshList" :promotion="null" :readOnly="false" />

  </b-card>
</b-col>

<b-col
            :md="window.innerWidth<=1200 && window.innerWidth>1024 ? 6 : 7"
           
            class="mb-1"
          >
  <b-card title="Liste des codes générés" class="h-95">
    <b-dropdown pill variant="outline-secondary" class="top-button top-right right" :text="currentPromoFilter.text">
      <b-dropdown-item v-if="currentPromoFilter.value!='date_asc'" @click="selectPromoFilter('date_asc','Les plus récents')">Les plus récents</b-dropdown-item>
      <b-dropdown-item v-if="currentPromoFilter.value!='date_desc'" @click="selectPromoFilter('date_desc','Les plus anciens')">Les plus anciens</b-dropdown-item>
      <b-dropdown-item v-if="currentPromoFilter.value!='use'" @click="selectPromoFilter('use','Les plus utilisés')">Les plus utilisés</b-dropdown-item>
    </b-dropdown>
      
    <div class="round-element black">
      <img src='@/assets/images/percent.svg' class="icon" width="70" />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="window.innerWidth > 768 ? window.innerWidth<=1200 && window.innerWidth>1024 ? columnsipad : columns : columnsmob"
      :rows="promotions"
      :row-style-class="getRowClass"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
        class="test"
      >
        <span
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>
       
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'amount'">
          <b-badge variant="success">-{{ props.formattedRow[props.column.field] }}%</b-badge>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'code'">
          <template v-if="props.row.status!='deleted'"> <span class="uppercase">{{ props.formattedRow[props.column.field] }}</span></template>
          <template v-else><span class="uppercase">{{ promoCodeLight(props.formattedRow[props.column.field] ) }}</span></template>
         
          <div @click="copyCode(props.formattedRow[props.column.field],props.index)" v-if="props.row.status!='deleted'" class="cc">
            
              <img v-if="copied[props.index]==false" src='@/assets/images/copy.svg' />
              <img v-else class="copied" src='@/assets/images/ok.svg' />
          </div>
        </span>
        
        <span v-else-if="props.column.field === 'active'">
          <template v-if="props.row.status!='deleted'">
            <div v-if="window.innerWidth<=768">
              <template v-if="props.formattedRow[props.column.field]">
                <b-badge  variant="success" class="round-badge"></b-badge>
              </template>
              <template v-else>
                <b-badge  variant="danger"  class="round-badge"></b-badge>
              </template>
            </div>
            <div v-else>
            <b-form-checkbox
                  v-model="props.formattedRow[props.column.field]"
                  @change="activatePromotion(props.row.id,props.formattedRow[props.column.field])"
                  class="custom-control-primary"
                > <template v-if="props.formattedRow[props.column.field]">
            
            Actif
            </template>
            <template v-else>
              Inactif
            </template>
            
                  
                </b-form-checkbox>
              </div>
          </template>
          <span v-else>
            Supprimé
          </span>
          
        </span>
        <span v-else-if="props.column.field === 'action'">

          <!-- <img src='@/assets/images/edit.svg'  @click="$router.push({name:'editPromotion', params: { id: props.row.id }})" class="icon-action" width="70" /> -->
          <img src='@/assets/images/edit.svg'  @click="openPopup(props.row, props.index)" class="icon-action" width="70" />

          <img v-if="(window.innerWidth<=1024 || window.innerWidth>1200) && props.row.status!='deleted'" src='@/assets/images/delete.svg' v-b-modal.modal-danger @click="selectedItem=props.index" class="icon-action contenudesk" width="70" />
          
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Page 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> sur {{ props.total }} résultats </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="totalRows"
              v-model="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
        <b-modal
          id="modal-danger"
          cancel-variant="outline-secondary"
          cancel-title="Non"
          ok-variant="danger"
          ok-title="Oui"
          @ok="deletePromotion(selectedItem)"
          modal-class="modal-danger"
          centered
          title="Êtes-vous sûr ?"
        >
          <b-card-text>
            Attention, cette action est irréversible ! Êtes-vous sûr de vouloir supprimer cette promotion ?
          </b-card-text>
        </b-modal>
      </template>
    </vue-good-table>

  </b-card>
</b-col>
</b-row>
</b-overlay>
</template>

<script>
import {BModal, BButton, BOverlay, VBModal,BCol,BRow, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BFormCheckbox} from 'bootstrap-vue'
import {APIRequest} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PromotionForm from './PromotionForm.vue'
import PopupPromotion from './PopupPromotion.vue'


export default {
  components: {
    BCard,
    
    BCardText,
    PromotionForm,
    VueGoodTable,
    BFormCheckbox,
    PopupPromotion,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,


    BRow,
    BCol,
    BDropdownItem,
    BOverlay,
    BModal,
    BButton
  },
  data() {
    return {
      promotions:[],
      window:window,
      currentPromo:null,
      currentIndex:null,
      pageLength: 10,
      totalRows:0,
      currentPage:1,
      dir: false,
      loading:true,
      selectedItem:null,

      columnsipad: [
        {
          label: 'Code',
          field: 'code',
          tdClass: 'text-left',
        },
      
        {
          label: 'Réduction',
          field: 'amount',
        },
        

        {
          label: 'Status',
          field: 'active',
          tdClass: window.innerWidth > 768 ? 'text-left' :'',
        },

        
        {
          label: 'Action',
          field: 'action',
        }
        
      ],
      
      columns: [
        {
          label: 'Code',
          field: 'code',
          tdClass: 'text-left',
          
        },
      
        {
          label: 'Réduction',
          field: 'amount',
        },
        {
          label: 'Total d\'utilisations',
          field: 'use_count',
        },

        {
          label: 'Status',
          field: 'active',
          tdClass: window.innerWidth > 768 ? 'text-left' :'',
        },

        
        {
          label: 'Action',
          field: 'action',
        }
        
      ],
      columnsmob: [
        {
          label: 'Code',
          field: 'code',
          tdClass: 'text-left',
        },
      
        {
          label: 'Réduction',
          field: 'amount',
        },
        
        {
          label: 'Status',
          field: 'active',
          tdClass: window.innerWidth > 768 ? 'text-left' :'',
        },

        
        {
          label: 'Action',
          field: 'action',
        }
        
      ],
      rows: [],
      searchTerm: '',
      currentPromoFilter:{text:'Les plus récents',value:'date_asc'},
      copied:[]

    }
  },
  watch:{
    currentPage:async function(){
      this.loading=true
      let offset=this.currentPage*this.pageLength
      let promotions=await APIRequest.list('promotions', localStorage.id_user_senseye_auth, null, this.pageLength,offset)
      this.promotions=promotions[0]
      this.copied=[]
      for(let i=0;i<this.promotions.length;i++)
      {
        this.copied.push(false)
      }
      this.loading=false
    }
  },
  methods:{
    promoCodeLight(code)
        {
            // Find the index of the last occurrence of "-"
            const lastDashIndex = code.lastIndexOf("-");

            // If "-" exists, return the substring before it; otherwise, return the original string
            return lastDashIndex !== -1
            ? code.substring(0, lastDashIndex)
            : code;
        },
    getRowClass(row) {
      if (row.status === 'deleted') {
        return 'inactive-row';
      }
      return '';
    },
    copyCode(code,index) {
      //console.log(index)
            this.copied[index] = true;
            this.$forceUpdate()
            setTimeout(() => {
                this.copied[index] = false;
                this.$forceUpdate()
            }, 2000);
            navigator.clipboard.writeText(code.toUpperCase());
        },
    selectPromoFilter(value,text)
    {
      this.currentPromoFilter.text=text
      this.currentPromoFilter.value=value

      this.filterPromotions()
    },
    async filterPromotions()
    {
      if (this.currentPromoFilter.value === 'use') {
        this.promotions.sort((a, b) => b.use_count - a.use_count);
      }
      else 
      {
        this.promotions.sort((a, b) => {
        const dateA = new Date(a.begin_date);
        const dateB = new Date(b.begin_date);
        if (this.currentPromoFilter.value === 'date_asc') {
          return dateA - dateB;
        } else if (this.currentPromoFilter.value === 'date_desc') {
          return dateB - dateA;
        }
      });
      }

     
    },
    async activatePromotion(id,active)
    {
      const index = this.promotions.findIndex(promo => promo.id === id);
      this.promotions[index].active=active

      let datas=this.promotions[index]
      datas.active=active
      
      await APIRequest.update('promotions',id,datas)
      

    },
    openPopup(promo,index){
      this.currentPromo=promo
      this.currentIndex=index
      if(this.$refs.popupPromotionRef)
      {
        this.$refs.popupPromotionRef.openPopup()
      }
     
    },
    async refreshList(){
      this.loading=true
      let offset=this.currentPage*this.pageLength
      let promotions=await APIRequest.list('promotions', localStorage.id_user_senseye_auth, null, this.pageLength,offset)
      this.promotions=promotions[0]
      this.copied=[]
      for(let i=0;i<this.promotions.length;i++)
      {
        this.copied.push(false)
      }
      this.loading=false

    },
    async deletePromotion(index)
    {
      this.loading=true
      try {
    
        let date = new Date(); // Create a Date object
        let timestamp = date.getTime(); // Convert to timestamp
        let Parameters=this.promotions[index]
       
        Parameters.status='deleted'
        Parameters.code=this.promotions[index].code+'-'+timestamp
   
        await APIRequest.update('promotions',this.promotions[index].id, Parameters)
      
        let promotions=await  APIRequest.list_with_request('promotions','?user='+localStorage.id_user_senseye_auth)
        this.promotions=promotions[0]
        this.copied=[]
      for(let i=0;i<this.promotions.length;i++)
      {
        this.copied.push(false)
      }
        this.totalRows=promotions[1]
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'La promotion a été supprimée !',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        
      } catch (error) {
        console.log('errooor')
        console.log(error)
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Une erreur est survenue.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        
      }
      
    }
  },
  async mounted()
  {
    let promotions=await APIRequest.list_with_request('promotions','?user='+localStorage.id_user_senseye_auth)
    this.promotions=promotions[0]
    this.copied=[]
      for(let i=0;i<this.promotions.length;i++)
      {
        this.copied.push(false)
      }
    this.filterPromotions()
    this.totalRows=promotions[1]
    this.loading=false
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
.uppercase {
  text-transform: uppercase;
}
.cc img
{
  width:15px;
}
.cc 
{
  cursor: pointer;
  display:inline-block;
  float:right;
  width:15px;
}
.input-upper::placeholder
{
  text-transform: none;
}
.text-muted
{
  color:#999999 !important;
    font-style: italic;
    font-size:13px !important;
}
.icon-action {
  cursor: pointer;
}
.h-95
{
  height:98%;
}
.round-badge.badge-success,.b-avatar-badge.badge-success
{
  
  background-color: #29C770;

}
.round-badge
{
  margin-right:5px;
}
.badge-success
{
  color:#29C770;
  background-color: #E2F7E8;
  border-radius: 10px;
}
.top-right
{
  left:240px;
}
.inactive-row
{
  background:#f8f8f8;
}
@media screen and (min-width:768px) and (max-width:1024px)
{
 
  .row .col-md-5,.row .col-md-7
  {
    width:100% !important;
    max-width:100% !important;
    flex:0 0 100% !important;
  }
  
}
</style>
